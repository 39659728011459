<template>
	<modal class="NoxModalPhoenixSettings" name="NoxModalPhoenixSettings" modal-width="700" :width="700" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'editSettings'">
						<span v-if="noxId == 106">Текущие показатели из CLV</span>
						<span v-else>Редактирование настройки</span>
					</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div v-if="noxType == 'editSettings'">
							<div v-if="noxId == 106">
								<div class="nox_phoenix_settings_currency_pairs">
									<table class="nox_table">
										<thead>
											<tr>
												<th></th>
												<th><font-awesome-icon :icon="['fas', 'asterisk']" /></th>
												<th><font-awesome-icon :icon="['fas', 'exclamation-circle']" /></th>
												<th :class="{ active: noxIsActiveCurrencyPairsSellTrading }" @click="currencyPairsToggleChangeAll(3)"><font-awesome-icon :icon="['fas', 'plus']" /></th>
												<th :class="{ active: noxIsActiveCurrencyPairsSellClosing }" @click="currencyPairsToggleChangeAll(4)"><font-awesome-icon :icon="['fas', 'times']" /></th>
												<th></th>
												<th><span>Продажа</span>|<span>Покупка</span></th>
												<th></th>
												<th :class="{ active: noxIsActiveCurrencyPairsBuyClosing }" @click="currencyPairsToggleChangeAll(2)"><font-awesome-icon :icon="['fas', 'times']" /></th>
												<th :class="{ active: noxIsActiveCurrencyPairsBuyTrading }" @click="currencyPairsToggleChangeAll(1)"><font-awesome-icon :icon="['fas', 'plus']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxCurrencyPairsIndexs" :key="index">
												<td data-label="Валютная пара:"><span class="uppercase">{{ value }}</span></td>
												<td data-label="Множитель:"><input type="text" class="nox_input" v-model.trim="noxCurrencyPairsMultipliers[index]" placeholder="множитель" autocomplete="off"></td>
												<td data-label="Статус тренда:"><input type="text" class="nox_input" v-model.trim="noxCurrencyPairsTrendStatuses[index]" placeholder="статус тренда" autocomplete="off"></td>
												<td data-label="SellTrading:"><toggle-button class="nox_toggle_button" :value="currencyPairsToggleValue(noxCurrencyPairsSellTrading[index])" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(3, index, $event)"></toggle-button></td>
												<td data-label="SellClosing:"><toggle-button class="nox_toggle_button" :value="currencyPairsToggleValue(noxCurrencyPairsSellClosing[index])" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(4, index, $event)"></toggle-button></td>
												<td data-label="SellValue:" :class="[noxCurrencyPairsSellValues[index] > 1 ? 'green' : (noxCurrencyPairsSellValues[index] < 1 ? 'red' : '')]">{{ noxCurrencyPairsSellValues[index].toFixed(2) }}</td>
												<td data-label=""><vue-slider class="nox_range_slider" :min="0.50" :max="1.50" :interval="0.01" :tooltip-formatter="currencyPairsRangeFormatter" v-model="noxCurrencyPairsRanges[index]" @change="currencyPairsRangeChange(index, $event)"></vue-slider></td>
												<td data-label="BuyValue:" :class="[noxCurrencyPairsBuyValues[index] > 1 ? 'green' : (noxCurrencyPairsBuyValues[index] < 1 ? 'red' : '')]">{{ noxCurrencyPairsBuyValues[index].toFixed(2) }}</td>
												<td data-label="BuyClosing:"><toggle-button class="nox_toggle_button" :value="currencyPairsToggleValue(noxCurrencyPairsBuyClosing[index])" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(2, index, $event)"></toggle-button></td>
												<td data-label="BuyTrading:"><toggle-button class="nox_toggle_button" :value="currencyPairsToggleValue(noxCurrencyPairsBuyTrading[index])" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(1, index, $event)"></toggle-button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div v-else>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Ключ настройки:</div>
									<div class="nox_modal_info_row_value">
										<div class="nox_modal_info_row_value_text">{{ noxSettingsKey }}</div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Тип настройки:</div>
									<div class="nox_modal_info_row_value">
										<v-select class="nox_select style-chooser" v-model.trim="noxSettingsType" :options="$parent.noxPhoenixSettingsTypes"></v-select>
										<div v-html="noxAlertSettingsType"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Значение настройки:</div>
									<div class="nox_modal_info_row_value">
										<div v-if="[1,2,6,9,10,11,12,13,14,15,21,25,26,27,29,30,31,32,34,35,45,46,48,49,66,67,68,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,97,99,100,101,102,103,104,105,113,114,115,116,117,118,120,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,188,189,190,191,201,202,203,204,206,207,208,209,218,223,224,225,226,228,229,230,234,235,236,237,239,240,241,242,243,244,245,246,251,252,253,254,255,257,258,259,260,261,262,263,264,265,267,268,269,270,271,272,273,274,275,276,277,278,279,280,281,282,283,284,285,286,287,288,289,290,291,292,293,294,295,296,297,298,299,319,321,325,342,343,344,345,357,359,361,364,366,369,370,371,372,373,374,375,376,377,378,379,380,381,382,384,386,388,391,393,396,397,398,399,400,401,402,403,404,405,406,407,408,409,414,420,421,422,423,424,425].includes(noxId)">
											<input type="number" class="nox_input" v-model.number="noxSettingsValue" placeholder="значение настройки" autocomplete="off">
										</div>
										<div v-else-if="[4,5,16,18,28,33,36,40,44,57,58,59,95,128,166,167,170,187,205,216,217,221,222,238,248,249,250,256,320,322,324,326,362,367,389,394,410,426,434,435].includes(noxId)">
											<toggle-button class="nox_toggle_button" :value="settingsValueToggleValue()" :width="42" :height="26" :color="{ checked: '#00FF0080', unchecked: '#FF6666CC' }" @change="settingsValueToggleChange"></toggle-button>
										</div>
										<div v-else-if="noxId == 17">
											<div class="nox_checkboxes">
												<label class="nox_checkbox" :for="'nox_checkbox_allowed_currencies_' + index" v-for="(value, index) in noxAllowedCurrencies" :key="index">
													<input type="checkbox" :id="'nox_checkbox_allowed_currencies_' + index" v-model.trim="noxAllowedCurrenciesStates[index]">
													<span class="nox_checkbox_mark"></span>
													<span class="nox_checkbox_text">{{ value }}</span>
												</label>
											</div>
										</div>
										<div v-else-if="[127,165,200,220,266].includes(noxId)">
											<v-select class="nox_select style-chooser" v-model.trim="noxSettingsValue" :options="(noxId == 127 ? ['0', '1', '2', '3'] : (noxId == 165 ? ['0', '1', '2'] : (noxId == 200 ? ['0', '1', '2'] : (noxId == 220 ? ['OFF', 'AC', 'AH', '0.9', '0.8', '0.7', '0.6', '0.5', 'MAX'] : ['0', '1', '2', '3', '4']))))"></v-select>
										</div>
										<div v-else>
											<input type="text" class="nox_input" v-model.trim="noxSettingsValue" placeholder="значение настройки" autocomplete="off">
										</div>
										<div v-html="noxAlertSettingsValue"></div>
									</div>
								</div>
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Название настройки:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxSettingsName" placeholder="название настройки" autocomplete="off">
										<div v-html="noxAlertSettingsName"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="submit" class="nox_button common green" @click="axios" v-if="noxType == 'editSettings'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertSettingsKey: '',
			noxAlertSettingsType: '',
			noxAlertSettingsName: '',
			noxAlertSettingsValue: '',
			noxId: 0,
			noxKey: '',
			noxType: '',
			noxGroup: 0,
			noxIndex: 0,
			noxSettingsKey: '',
			noxSettingsType: '',
			noxSettingsName: '',
			noxSettingsValue: '',
			noxCurrencyPairsIndexs: [],
			noxCurrencyPairsMultipliers: [],
			noxCurrencyPairsTrendStatuses: [],
			noxCurrencyPairsRanges: [],
			noxCurrencyPairsBuyValues: [],
			noxCurrencyPairsBuyTrading: [],
			noxCurrencyPairsBuyClosing: [],
			noxCurrencyPairsSellValues: [],
			noxCurrencyPairsSellTrading: [],
			noxCurrencyPairsSellClosing: [],
			noxAllowedCurrencies: [],
			noxAllowedCurrenciesStates: [],
			noxIsActiveCurrencyPairsBuyTrading: false,
			noxIsActiveCurrencyPairsBuyClosing: false,
			noxIsActiveCurrencyPairsSellTrading: false,
			noxIsActiveCurrencyPairsSellClosing: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxKey = event.params.key;
				this.noxType = event.params.type;
				this.noxGroup = event.params.group;
				this.noxIndex = event.params.index;
				this.noxSettingsKey = '';
				this.noxSettingsType = '';
				this.noxSettingsName = '';
				this.noxSettingsValue = '';
				this.noxCurrencyPairsIndexs = [];
				this.noxCurrencyPairsMultipliers = [];
				this.noxCurrencyPairsTrendStatuses = [];
				this.noxCurrencyPairsRanges = [];
				this.noxCurrencyPairsBuyValues = [];
				this.noxCurrencyPairsBuyTrading = [];
				this.noxCurrencyPairsBuyClosing = [];
				this.noxCurrencyPairsSellValues = [];
				this.noxCurrencyPairsSellTrading = [];
				this.noxCurrencyPairsSellClosing = [];
				this.noxAllowedCurrencies = ['aud', 'cad', 'eur', 'usd'];
				this.noxAllowedCurrenciesStates = [];
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editSettings') {

					this.noxData = this.$parent.noxPhoenixSettingsForGroups[this.noxGroup][this.noxIndex];
					this.noxSettingsKey = this.noxData.key;
					this.noxSettingsType = this.$parent.noxPhoenixSettingsTypes[this.noxData.type];
					this.noxSettingsName = this.noxData.name;
					this.noxSettingsValue = this.noxData.value;

					if ([17,106].includes(this.noxId)) {
						var array = this.noxSettingsValue.split(',');
						var index = 0;
						if (this.noxId == 17) {
							for (var j in this.noxAllowedCurrencies) {
								this.noxAllowedCurrenciesStates[j] = array.indexOf(this.noxAllowedCurrencies[j]) >= 0 ? true : false;
							}
						}
						else if (this.noxId == 106) {
							this.noxIsActiveCurrencyPairsBuyTrading = true;
							this.noxIsActiveCurrencyPairsBuyClosing = true;
							this.noxIsActiveCurrencyPairsSellTrading = true;
							this.noxIsActiveCurrencyPairsSellClosing = true;
							for (var i = 0; i < array.length; i += 9) {
								this.noxCurrencyPairsIndexs[index] = array[i];
								this.noxCurrencyPairsMultipliers[index] = Number(array[i + 1]).toFixed(1);
								this.noxCurrencyPairsTrendStatuses[index] = Number(array[i + 2]).toFixed(1);
								this.noxCurrencyPairsRanges[index] = Number(array[i + 3]);
								this.noxCurrencyPairsBuyValues[index] = Number(array[i + 3]);
								this.noxCurrencyPairsBuyTrading[index] = Number(array[i + 4]);
								this.noxCurrencyPairsBuyClosing[index] = Number(array[i + 5]);
								this.noxCurrencyPairsSellValues[index] = Number(array[i + 6]);
								this.noxCurrencyPairsSellTrading[index] = Number(array[i + 7]);
								this.noxCurrencyPairsSellClosing[index++] = Number(array[i + 8]);
								if (!Number(array[i + 4])) { this.noxIsActiveCurrencyPairsBuyTrading = false; }
								if (!Number(array[i + 5])) { this.noxIsActiveCurrencyPairsBuyClosing = false; }
								if (!Number(array[i + 7])) { this.noxIsActiveCurrencyPairsSellTrading = false; }
								if (!Number(array[i + 8])) { this.noxIsActiveCurrencyPairsSellClosing = false; }
							}
						}
					}
					else if ([37,38,39,41,42,43,51,52,53,54,55,56,60,61,62,63,64,65,69,70,71,93,94,96,98,107,108,109,110,111,112,119,121,122,123,124,125,126,168,169,192,193,194,195,196,197,198,199,210,211,212,213,214,215,219,231,232,233,300,301,302,303,304,305,306,307,308,309,310,311,312,313,314,315,316,317,318,323,327,328,329,330,331,332,333,334,335,336,337,338,339,340,341,346,347,348,349,350,351,352,353,354,355,356,358,368,383,385,395,415,416,417,418,419,427,428,429,430,431,432,433,436,437,438].includes(this.noxId)) {
						if (this.$parent.$parent.$parent.isInteger(this.noxSettingsValue)) {
							this.noxSettingsValue = this.noxSettingsValue.toFixed(1);
						}
					}
					else if ([127,165,200,220,266].includes(this.noxId)) {
						this.noxSettingsValue = String(this.noxSettingsValue);
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertSettingsKey = '';
				this.noxAlertSettingsType = '';
				this.noxAlertSettingsName = '';
				this.noxAlertSettingsValue = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать ключ настройки.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный ключ настройки.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать значение настройки.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верное значение настройки.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать название настройки.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное название настройки.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать тип настройки.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный тип настройки.'; }
				else if (i == 9) { this.noxTemp = 'Ключ настройки не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(event) {

				event.preventDefault();

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/phoenix/settings',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'editSettings') {
						if ([17,106].includes(_this.noxId)) {
							_this.noxSettingsValue = [];
							if (_this.noxId == 17) {
								for (var j in _this.noxAllowedCurrenciesStates) {
									if (_this.noxAllowedCurrenciesStates[j] === true) {
										_this.noxSettingsValue.push(_this.noxAllowedCurrencies[j]);
									}
								}
							}
							else if (_this.noxId == 106) {
								for (var i in _this.noxCurrencyPairsIndexs) {
									_this.noxSettingsValue.push(_this.noxCurrencyPairsIndexs[i], (isNaN(Number(_this.noxCurrencyPairsMultipliers[i])) || Number(_this.noxCurrencyPairsMultipliers[i]) < 0.1 || Number(_this.noxCurrencyPairsMultipliers[i]) > 1 ? 1 : (Math.round(_this.noxCurrencyPairsMultipliers[i] * 10) / 10)), (isNaN(Number(_this.noxCurrencyPairsTrendStatuses[i])) || Number(_this.noxCurrencyPairsTrendStatuses[i]) < 0 || Number(_this.noxCurrencyPairsTrendStatuses[i]) > 1 ? 1 : (Math.round(_this.noxCurrencyPairsTrendStatuses[i] * 10) / 10)), (Math.round(_this.noxCurrencyPairsBuyValues[i] * 100) / 100), _this.noxCurrencyPairsBuyTrading[i], _this.noxCurrencyPairsBuyClosing[i], (Math.round(_this.noxCurrencyPairsSellValues[i] * 100) / 100), _this.noxCurrencyPairsSellTrading[i], _this.noxCurrencyPairsSellClosing[i]);
								}
							}
							_this.noxSettingsValue = _this.noxSettingsValue.join(',');
						}
						if (String(_this.noxSettingsValue) == '') {
							_this.noxAlertSettingsValue = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxSettingsValue)) {
							_this.noxAlertSettingsValue = _this.getError(4);
						}
						if (!_this.noxSettingsName) {
							_this.noxAlertSettingsName = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxSettingsName)) {
							_this.noxAlertSettingsName = _this.getError(6);
						}
						if (!_this.noxIsError) {
							config.data = { key: _this.noxKey, value: _this.noxSettingsValue.toString(), name: _this.noxSettingsName, type: Number(_this.noxSettingsType.code) };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'editSettings') {
									_this.$parent.noxPhoenixSettingsForGroups[_this.noxGroup].splice(_this.noxIndex, 1, data.data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'editSettings') {
										if      (data.response.data.error == 'KEY_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'KEY_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'VALUE_IS_EMPTY') { _this.noxAlertSettingsValue = _this.getError(3); }
										else if (data.response.data.error == 'VALUE_NOT_VALID') { _this.noxAlertSettingsValue = _this.getError(4); }
										else if (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertSettingsName = _this.getError(5); }
										else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertSettingsName = _this.getError(6); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertSettingsType = _this.getError(7); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertSettingsType = _this.getError(8); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'editSettings') {
										if (data.response.data.error == 'KEY_NOT_FOUND') { _this.noxAlert = _this.getError(9); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			settingsValueToggleValue: function() {
				return this.noxSettingsValue === 'true';
			},
			settingsValueToggleChange: function(event) {
				this.noxSettingsValue = event.value.toString();
			},
			currencyPairsToggleValue: function(value) {
				return value > 0 ? true : false;
			},
			currencyPairsToggleChange: function(type, index, event) {
				if      (type == 1) { this.noxCurrencyPairsBuyTrading.splice(index, 1, Number(event.value)); }
				else if (type == 2) { this.noxCurrencyPairsBuyClosing.splice(index, 1, Number(event.value)); }
				else if (type == 3) { this.noxCurrencyPairsSellTrading.splice(index, 1, Number(event.value)); }
				else if (type == 4) { this.noxCurrencyPairsSellClosing.splice(index, 1, Number(event.value)); }
			},
			currencyPairsToggleChangeAll: function(type) {
				if (type == 1) {
					this.noxIsActiveCurrencyPairsBuyTrading = !this.noxIsActiveCurrencyPairsBuyTrading;
					for (var i1 in this.noxCurrencyPairsBuyTrading) {
						this.noxCurrencyPairsBuyTrading.splice(i1, 1, Number(this.noxIsActiveCurrencyPairsBuyTrading));
					}
				}
				else if (type == 2) {
					this.noxIsActiveCurrencyPairsBuyClosing = !this.noxIsActiveCurrencyPairsBuyClosing;
					for (var i2 in this.noxCurrencyPairsBuyClosing) {
						this.noxCurrencyPairsBuyClosing.splice(i2, 1, Number(this.noxIsActiveCurrencyPairsBuyClosing));
					}
				}
				else if (type == 3) {
					this.noxIsActiveCurrencyPairsSellTrading = !this.noxIsActiveCurrencyPairsSellTrading;
					for (var i3 in this.noxCurrencyPairsSellTrading) {
						this.noxCurrencyPairsSellTrading.splice(i3, 1, Number(this.noxIsActiveCurrencyPairsSellTrading));
					}
				}
				else if (type == 4) {
					this.noxIsActiveCurrencyPairsSellClosing = !this.noxIsActiveCurrencyPairsSellClosing;
					for (var i4 in this.noxCurrencyPairsSellClosing) {
						this.noxCurrencyPairsSellClosing.splice(i4, 1, Number(this.noxIsActiveCurrencyPairsSellClosing));
					}
				}
			},
			currencyPairsRangeChange: function(index, event) {
				this.noxCurrencyPairsRanges.splice(index, 1, Number(event));
				this.noxCurrencyPairsBuyValues.splice(index, 1, Number(event));
				this.noxCurrencyPairsSellValues.splice(index, 1, 2 - Number(event));
			},
			currencyPairsRangeFormatter: function(event) {
				return Number(event).toFixed(2);
			},
			close: function() {
				this.$modal.hide('NoxModalPhoenixSettings');
			}
		}
	}
</script>

<style>
	.nox_phoenix_settings_currency_pairs table tr th,
	.nox_phoenix_settings_currency_pairs table tr td {
		padding: 2px 10px;
		border: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_phoenix_settings_currency_pairs table tr th {
		padding: 10px 2px;
	}
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(2),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(2),
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(3),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(3) {
		padding: 2px;
		width: 40px;
	}
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(3),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(3) {
		padding: 2px 15px 2px 2px;
		width: 53px;
	}
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(2) .nox_input,
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(3) .nox_input {
		border-bottom: 1px solid #CCCCCC;
		text-align: center;
		max-width: 100px;
		height: 25px;
	}
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(4),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(4),
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(5),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(5),
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(9),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(9),
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(10),
	.nox_phoenix_settings_currency_pairs table tr td:nth-child(10) {
		padding: 2px;
		cursor: pointer;
		width: 1px;
	}
	.nox_phoenix_settings_currency_pairs table tr th:first-child,
	.nox_phoenix_settings_currency_pairs table tr td:first-child {
		padding: 2px 10px 2px 0;
		text-align: left;
	}
	.nox_phoenix_settings_currency_pairs table tr th:last-child,
	.nox_phoenix_settings_currency_pairs table tr td:last-child {
		padding: 2px 0 2px 2px;
	}
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(7) span {
		font-size: 12px;
	}
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(7) span:first-child {
		padding: 0 5px 0 0;
	}
	.nox_phoenix_settings_currency_pairs table tr th:nth-child(7) span:last-child {
		padding: 0 0 0 5px;
	}
	.nox_phoenix_settings_currency_pairs .vue-js-switch {
		margin: 0;
	}
	.nox_phoenix_settings_currency_pairs .nox_range_slider .vue-slider-rail {
		background: rgb(255,0,0);
		background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,255,1) 50%, rgba(0,128,0,1) 100%);
	}
	.nox_phoenix_settings_currency_pairs .nox_range_slider .vue-slider-process,
	.nox_phoenix_settings_currency_pairs .nox_range_slider:hover .vue-slider-process {
		background: none;
	}
	.nox_phoenix_settings_currency_pairs .nox_range_slider .vue-slider-dot-tooltip {
		display: none;
	}

	@media (max-width: 1200px) {
		.nox_phoenix_settings_currency_pairs table tr td {
			padding: 7px 12px !important;
			border: 1px solid #999999;
			border-bottom: 0;
			text-align: right !important;
		}
		.nox_phoenix_settings_currency_pairs table tr td .nox_input {
			border: none !important;
			text-align: right !important;
		}
	}
</style>
